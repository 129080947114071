<template>
  <div class="fullScreen" :class="{ show: showEditDialog }" v-loading="loading">
    <Crumbs>
      <el-breadcrumb-item slot="after">
        {{ options.list_s !== 2 ? '审核报销单详情' : '查看报销单详情' }}
      </el-breadcrumb-item>
    </Crumbs>
    <div class="fullScreenMain" v-loading="loading">
      <div class="fullScreenMainCon" id="Scrollbar">
        <el-form ref="form" :model="form" :rules="rules" style="margin-bottom: 20px">
          <el-descriptions direction="vertical" :column="6" border style="width: 100%">
            <el-descriptions-item
              labelClassName="bussinessLabelClassName"
              contentClassName="bussinessContentClassName"
              label="单据编号"
            >
              <el-form-item prop="expenseNumber" ref="expenseNumber">
                {{ form.expenseNumber }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="bussinessLabelClassName"
              contentClassName="bussinessContentClassName"
              label="申请人"
            >
              <el-form-item prop="createName" ref="createName">
                {{ form.createName }}
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              label="部门"
              labelClassName="bussinessLabelClassName"
              contentClassName="bussinessContentClassName"
            >
              <el-form-item prop="deptList" ref="deptList">
                {{ form.deptName }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="bussinessLabelClassName"
              contentClassName="bussinessContentClassName"
              label="领款人"
            >
              <el-form-item prop="payeeName" ref="payeeName">
                {{ form.payeeName }}
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              labelClassName="bussinessLabelClassName"
              contentClassName="bussinessContentClassName"
              label="申请日期"
            >
              <el-form-item prop="taskDate" ref="taskDate">
                {{ form.taskDate | dateFormat }}
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              labelClassName="bussinessLabelClassName"
              contentClassName="bussinessContentClassName"
              label="费用所属单位"
            >
              <el-form-item prop="companyType" ref="companyType">
                {{ getCompanyTypeName(form.companyType) }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              :span="1"
              label-class-name="businessOutSource bussinessLabelClassName"
              content-class-name="businessOutSource bussinessContentClassName"
            >
              <template slot="label">
                <span style="color: #000">费用类别</span>
              </template>
              <span style="color: #000; font-size: 17px">商务费用报销</span>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="bussinessLabelClassName"
              contentClassName="bussinessContentClassName"
              :span="1"
              label="是否优先发放"
            >
              <el-radio :disabled="!form.isDisplayEditButton" v-model="form.priority" :label="1"
                >是</el-radio
              >
              <el-radio :disabled="!form.isDisplayEditButton" v-model="form.priority" :label="0"
                >否</el-radio
              >
            </el-descriptions-item>

            <el-descriptions-item
              labelClassName="bussinessLabelClassName"
              contentClassName="bussinessContentClassName"
              label="付款方式"
              :span="2"
            >
              <el-form-item prop="applyExpenseType" ref="applyExpenseType">
                {{ form.applyExpenseType | dict(applyExpenseType) }}
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              labelClassName="bussinessLabelClassName"
              contentClassName="bussinessContentClassName"
              label="报销方式"
              :span="2"
            >
              <el-form-item prop="expenseMode" ref="expenseMode">
                {{ form.expenseMode | dict(dictData.expenseMode) }}
                <el-button
                  v-if="form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT'"
                  size="medium"
                  type="warning"
                  style="margin-left: 10%; padding: 5px"
                  @click="registrationIsShowFn"
                  >原登记单</el-button
                >
              </el-form-item>
            </el-descriptions-item>
          </el-descriptions>
        </el-form>

        <!-- 经费预支单据 -->
        <el-table
          v-if="form.counteractExpenseList && form.counteractExpenseList.length > 0"
          :data="form.counteractExpenseList"
          border
          id="table"
          style="margin-bottom: 10px"
          :row-style="{ height: '80px' }"
        >
          <el-table-column
            align="center"
            prop="expenseNumber"
            width="160"
            label="单据编号"
          ></el-table-column>
          <el-table-column
            prop="taskDate"
            align="center"
            label="申请日期"
            width="170"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              {{ scope.row.taskDate | dateFormat }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="cost" label="预支总金额">
            <template slot-scope="scope">
              {{ scope.row.cost | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="sumNotCounteractCost">
            <template slot="header" slot-scope="scope">
              <div>未冲销金额</div>
              <div style="color: red">（包括审批中的金额）</div>
            </template>
            <template slot-scope="scope">
              {{ scope.row.sumNotCounteractCost | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column
            prop="cost"
            label="冲销金额"
            align="center"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">{{ scope.row.counteractCost | applyAmount }} </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="100" :show-overflow-tooltip="false">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="expenditureDetailFn(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <el-descriptions title="" :column="1" border>
          <el-descriptions-item :span="1" labelStyle="width:180px;">
            <template slot="label"
              >支出类型
              <el-button
                style="margin-left: 15px"
                size="mini"
                type="warning"
                @click="disbursementTypeIsShow = true"
                >类型说明</el-button
              ></template
            >
            {{ form.disbursementType | dict(dictData.disbursementType) }}
          </el-descriptions-item>
          <el-descriptions-item labelStyle="width:100px;">
            <template slot="label">
              <template v-if="form.disbursementType == 'UNDEFINED_RATIFICATION_IMPLEMENT'">
                商务说明</template
              >
              <template v-else> 商务名称</template>
            </template>
            <template v-if="form.disbursementType == 'UNDEFINED_RATIFICATION_IMPLEMENT'"
              ><span style="display: inline-block; line-height: 40px; width: 70%">{{
                form.businessContent
              }}</span></template
            >
            <template v-else>
              <span style="display: inline-block; line-height: 40px; width: 70%">{{
                form.businessName
              }}</span>
            </template>
            <el-button
              style="float: right"
              v-if="form.status == 10 || form.status == 100"
              type="success"
              @click="exportFileZipFn"
              :loading="loading"
            >
              全部附件下载
            </el-button>
          </el-descriptions-item>
        </el-descriptions>

        <el-form class="column3 form_box table" label-position="top" style="width: 100%">
          <el-table
            :header-cell-class-name="headerInvoiceClassName"
            :data="form.detailList"
            id="table"
          >
            <el-table-column label="序号" align="center" prop="index" width="50">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="taskDate"
              label="日期"
              width="160"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <el-date-picker disabled v-model="scope.row.taskDate" type="date"> </el-date-picker>
              </template>
            </el-table-column>
            <el-table-column
              prop="typeOne"
              label="费用科目一"
              width="160"
              :show-overflow-tooltip="false"
            >
              <template slot="header" slot-scope="scope">
                费用科目一
                <div>
                  <el-button
                    size="mini"
                    type="warning"
                    :loading="typeDialogLoading"
                    @click="isDialogShow = true"
                    >科目说明</el-button
                  >
                </div>
              </template>
              <template slot-scope="scope">
                <Dictionary disabled v-model="scope.row.typeOne" code="CBFYZC" />
              </template>
            </el-table-column>
            <el-table-column
              prop="typeTwo"
              label="费用科目二"
              width="160"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <Dictionary disabled v-model="scope.row.typeTwo" :code="scope.row.typeOne" />
              </template>
            </el-table-column>
            <el-table-column
              prop="typeThree"
              label="费用科目三"
              width="160"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <Dictionary disabled v-model="scope.row.typeThree" :code="scope.row.typeTwo" />
              </template>
            </el-table-column>
            <el-table-column
              prop="cost"
              label="金额 (元)"
              width="120"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.cost | applyAmount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              scoped-slot
              prop="contentDetail"
              label="事由附件详细说明"
              :show-overflow-tooltip="false"
              width="250"
            >
              <template slot-scope="scope">
                <span>
                  <el-input
                    :disabled="!form.isDisplayEditButton"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 9999 }"
                    v-model="scope.row.contentDetail"
                  ></el-input
                ></span>
              </template>
            </el-table-column>
            <el-table-column
              align="left"
              min-width="260"
              label="发票附件上传"
              :show-overflow-tooltip="false"
            >
              <template slot="header">
                <div>发票附件上传</div>
                <div>只能上传pdf或图片.png .jpg .jpeg格式</div>
              </template>
              <template slot-scope="scope">
                <div
                  v-for="(n, index) in scope.row.expenseFileList"
                  :key="index"
                  class="invoice-file"
                >
                  <template v-if="n.fileType === 'INVOICE'">
                    <div class="invoice-file-left">
                      <div>
                        <a
                          v-if="isShowType(n)"
                          style="text-decoration: revert; color: #409eff; padding-right: 8px"
                          target="_blank"
                          :href="`${filepath}${n.filePath}`"
                        >
                          {{ n.fileName }}
                        </a>
                        <span v-else class="img_txt" @click="imgIsShow(n, index)">{{
                          n.fileName
                        }}</span>
                      </div>
                      <div>
                        <span style="color: red" v-if="n.fileInvoiceNoType === 'EXIST'"
                          >发票识别存疑</span
                        >
                        <!-- <span style="color: red" v-else>编码：{{ n.invoiceNo }}</span> -->
                      </div>
                    </div></template
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="expenseFileList" width="252" label="非发票附件上传">
              <template slot-scope="scope">
                <div
                  v-for="(n, index) in scope.row.expenseFileList"
                  :key="index"
                  style="margin-top: 5px"
                >
                  <template v-if="n.fileType === 'NORMAL'">
                    <a
                      v-if="isShowType(n)"
                      style="text-decoration: revert; color: #409eff; padding-right: 8px"
                      target="_blank"
                      :href="`${filepath}${n.filePath}`"
                    >
                      {{ n.fileName }}
                    </a>
                    <span v-else class="img_txt" @click="imgIsShow(n, index)">{{
                      n.fileName
                    }}</span>
                  </template>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
        <el-descriptions title="" :column="4" border>
          <el-descriptions-item
            label="原登记单总金额"
            v-if="form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT'"
            labelStyle="width:120px"
          >
            {{ form.registrationExpenditureTotalCost | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item
            label="报销合计"
            :span="form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT' ? 1 : 2"
            labelStyle="width:120px"
          >
            {{ amount | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item label="金额大写" :span="2" labelStyle="width:120px">
            {{ amount | numberParseChina }}
          </el-descriptions-item>
          <template v-if="form.applyExpenseType == 'ADVANCE_PAYMENT'">
            <template v-if="form.status != 100">
              <el-descriptions-item label="总预支费用" labelStyle="width:120px">
                {{ totalCounteract.sumAdvanceCost | applyAmount }}
              </el-descriptions-item>
              <el-descriptions-item labelStyle="width:150px">
                <template slot="label">
                  <div>　冲销中的金额</div>
                  <div>（包括本单的冲销）</div>
                </template>

                {{ writeOffMoney | applyAmount }}
              </el-descriptions-item></template
            >
            <el-descriptions-item
              label="本次冲销总金额"
              :span="form.status != 100 ? 1 : 2"
              labelStyle="width:120px"
            >
              {{ thisTimeMoney | applyAmount }}
            </el-descriptions-item>
            <el-descriptions-item
              label="报销支付金额"
              :span="form.status != 100 ? 1 : 2"
              labelStyle="width:120px"
            >
              <template v-if="paymentMony">
                {{ paymentMony | applyAmount }}
              </template>
              <template v-else> 0 </template>
            </el-descriptions-item>
          </template>
        </el-descriptions>
        <div
          v-if="form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT' && registrationShow"
          style="color: red"
        >
          请注意: 当前总金额已超过原登记单总金额 10%,审批流程将会重新走报销流程
        </div>

        <div style="height: 40px"></div>
        <OpinionArea
          :title="'报销进度'"
          :procInstId="this.options.processInstId"
          :instInvolved="instInvolved"
        ></OpinionArea>
      </div>
      <div class="fullScreenOperation">
        <el-button
          v-if="form.isDisplayEditButton"
          type="warning"
          :loading="loading"
          @click="tempSave"
        >
          编辑
        </el-button>
        <el-button
          v-if="options.list_s !== 2"
          type="success"
          :loading="loading"
          @click="showDialogForm(1)"
        >
          通过
        </el-button>
        <el-button
          v-if="options.list_s !== 2"
          type="danger"
          :loading="loading"
          @click="showDialogForm(2)"
        >
          不通过
        </el-button>
        <SelectDialog
          v-if="options.list_s !== 2 && form.status == 10 && permission(['FLOW_HAND_OVER'])"
          @selectDataChange="selectAuditor"
          style="display: inline-block"
        >
          <el-button slot="button" type="primary" :loading="loading"> 转审 </el-button>
        </SelectDialog>
        <el-button type="info" @click="re">返回</el-button>
      </div>
    </div>

    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      width="60%"
      :before-close="handleClose"
    >
      <div slot="title" style="color: #409eff">
        {{ dg_form.status == 1 ? '审批通过意见' : '审批不通过意见' }}
        <div
          v-if="isInvoiceUsed"
          style="
            display: flex;
            justify-content: center;
            font-weight: 900;
            font-size: 20px;
            color: red;
          "
        >
          <span>此发票号码识别存疑，请核查</span>
        </div>
      </div>
      <el-alert
        type="warning"
        show-icon
        :closable="false"
        v-if="
          this.auditStatus == 1 &&
          this.taskDefinitionKey == 'BUINESS_PRINCIPAL' &&
          this.form.disbursementType == 'THROUGH_RATIFICATION_IMPLEMENT'
        "
        style="margin: 0 20px 10px 10px"
      >
        <template>
          <div style="color: #000">
            当前商务支出类型为：<span style="color: red">经批准商务支出</span>, 该笔报销费用由<span
              style="color: red"
              >商务负责人记账70%</span
            >，<span style="color: red">商务部记账30%</span>
          </div>
        </template>
      </el-alert>
      <!-- <el-alert
        type="warning"
        show-icon
        :closable="false"
        v-else-if="
          this.auditStatus == 1 &&
          this.taskDefinitionKey == 'BUINESS_PRINCIPAL' &&
          this.form.disbursementType == 'COORDINATE_IMPLEMENT'
        "
        style="margin: 0 20px 10px 10px"
      >
        <template>
          <div style="color: #000">
            当前商务支出类型为：<span style="color: red">经批准商务支出</span>, 该笔报销费用由<span
              style="color: red"
              >商务负责人记账70%</span
            >，<span style="color: red">商务部记账30%</span>
          </div>
        </template>
      </el-alert> -->
      <el-form :model="dg_form" :rules="dg_formRules" ref="dg_formRef">
        <el-form-item prop="" v-if="dg_form.status == 1">
          <el-input
            @change="$forceUpdate()"
            v-model.trim="dg_form.opinion"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item prop="opinion" v-else>
          <el-input
            @change="$forceUpdate()"
            v-model.trim="dg_form.opinion"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button
          :type="dg_form.status == 1 ? 'success' : 'danger'"
          style="width: 120px"
          @click="addApprove"
          :loading="loading"
          >{{ dg_form.status == 1 ? '审批通过' : '审批不通过' }}</el-button
        >
        <el-button
          type="warning"
          style="margin-right: 37%; margin-left: 12%; width: 120px"
          plain
          :loading="loading"
          @click="handleClose"
          >取 消</el-button
        >
      </div>
    </el-dialog>

    <DialogImg
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
      :filepath="filepath"
      :imgList="imgList"
      :img_index="img_index"
    ></DialogImg>
    <el-dialog :visible.sync="registrationIsShow" append-to-body width="80%">
      <div slot="title" style="color: #409eff">登记单</div>
      <LogisticsRegistration :form="oldRegistrationInfo"></LogisticsRegistration>
    </el-dialog>

    <!-- 费用科目说明 -->
    <TypeDialog :isDialogShow.sync="isDialogShow" @changeLoading="handleChangeLoading"></TypeDialog>

    <el-dialog
      width="60%"
      title="支出类型说明"
      append-to-body
      :visible.sync="disbursementTypeIsShow"
    >
      <div class="imgDialog">
        <img
          :src="`${disbursementTypeUrl}?${new Date().getTime()}`"
          alt="支出类型说明"
          width="100%"
        />
      </div>
    </el-dialog>

    <!-- 经费预支详情 -->
    <ExpenditureAdvanceDetails
      :isShow.sync="expenditureIsShow"
      :options="expenditureOptions"
      @closeExpenditure="expenditureIsShow = false"
    ></ExpenditureAdvanceDetails>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    OpinionArea: () => import('@/components/OpinionArea.vue'),
    DialogImg: () => import('@/components/DialogImg.vue'),
    LogisticsRegistration: () => import('@/views/publicView/LogisticsRegistration.vue'),
    TypeDialog: () => import('@/components/typeDialog.vue'),
    ExpenditureAdvanceDetails: () => import('@/components/funds/expenditure-advance-details.vue'),
    SelectDialog: () => import('@/components/selectDialog.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
    amount() {
      let sum = 0
      if (this.form.detailList) {
        sum = this.form.detailList.reduce((total, item) => (total += Number(item.cost)), 0)
      }
      sum = sum.toFixed(2)
      return sum
    },
    // 人员记账
    memberTally() {
      let sum = 0
      let amount = this.amount
      if (amount) {
        sum = amount * 0.8
        sum = sum.toFixed(2)
      }
      return sum
    },
    deptTally() {
      let sum = 0
      let amount = this.amount
      if (amount) {
        sum = amount * 0.2
        sum = sum.toFixed(2)
      }
      return sum
    },
    registrationShow() {
      let isShow = false
      let num = this.form.registrationExpenditureTotalCost * 1.1
      if (this.amount > num) {
        isShow = true
      } else {
        isShow = false
      }
      return isShow
    },
    // 冲销中的金额
    writeOffMoney() {
      let num = 0
      num = this.totalCounteract.sumCounteractCost + this.thisTimeMoney
      return num
    },
    // 本次冲销总金额
    thisTimeMoney() {
      let num = 0
      if (this.form.counteractExpenseList) {
        this.form.counteractExpenseList.forEach(v => {
          num += Number(v.counteractCost)
        })
      }
      return num
    },
    // 报销支付金额
    paymentMony() {
      let num = 0
      num = this.amount - this.thisTimeMoney
      if (num > 0) {
        return num
      } else {
        return 0
      }
    },
    // 检查明细中发票附件是否已存在使用过
    isInvoiceUsed() {
      if (this.form.detailList) {
        return this.form.detailList.some(v =>
          v.expenseFileList.some(
            item => item.fileType === 'INVOICE' && item.fileInvoiceNoType === 'EXIST'
          )
        )
      }
    },
  },
  data() {
    return {
      taskDefinitionKey: null, //当前流程节点
      showEditDialog: false,
      loading: false,
      submitLoading: false,
      form: {},
      costType: 'costType',
      rules: {
        projectId: [{ required: true, message: '请选择商务', trigger: ['blur', 'change'] }],
      },
      companyTypeList: [],
      deptList: [], // 部门列表
      postList: [], //岗位列表
      type: 'portrait',
      businessNameList: [],
      state: '',
      priceChinese: '',
      projectCostList: [
        {
          id: '',
          tableData: [
            {
              taskDate: '',
              content: '',
              cost: 0,
              contentDetail: '',
            },
          ],
        },
      ],
      filepath: process.env.VUE_APP_FILEPATH,
      dialogFormVisible: false,
      dg_form: { opinion: '', status: 0 },
      dg_formRules: {
        opinion: [{ required: true, message: '请输入审批不通过意见', trigger: 'blur' }],
      },
      dictData: {
        expenseMode: [],
        disbursementType: [],
      },
      businessTyle: [],
      instInvolved: {}, //当前进度处理人数组
      dialogVisible: false,
      imgList: [],
      img_index: 0,
      oldRegistrationInfo: {},
      registrationIsShow: false,
      isDialogShow: false,
      typeDialogLoading: false,
      imgUrl: 'https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/科目数据.png',
      disbursementTypeIsShow: false,
      disbursementTypeUrl:
        'https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/商务支出类型.png',
      dg_formRules: {
        opinion: [{ required: true, message: '请输入审批不通过意见', trigger: 'blur' }],
      },
      auditStatus: 0,
      applyExpenseType: [], //付款方式字典
      expenditureOptions: {}, //预支详情
      expenditureIsShow: false, //预支详情弹窗
      totalCounteract: {
        sumAdvanceCost: 0, //经费预支总合计
        sumCounteractCost: 0, //	冲销中金额合计
      },
    }
  },
  inject: ['re'],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.showEditDialog = newVal
        if (newVal) {
          this.$nextTick(() => {
            let scroll = document.getElementById('Scrollbar')
            if (scroll) {
              scroll.scrollTop = 0
            }
          })
        }
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.dg_form = { opinion: '', status: 0 }

        if (newVal) {
          this.init()
        }
      },
    },
  },

  created() {
    this.$api.dict.listSysDictData('BUSINESS_DISBURSEMENT_TYPE', true).then(res => {
      this.businessTyle = res.data
    })
    this.$api.dict
      .listSysDictData('APPLY_EXPENSE_TYPE', true)
      .then(res => {
        this.applyExpenseType = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  filters: {
    applyAmount(n) {
      if (!n) return
      return Number(n).toLocaleString()
    },
  },
  methods: {
    //todo 暂存
    tempSave() {
      this.loading = true
      this.$api.businessExpense
        .undeterminedEdit(this.form)
        .then(res => {
          this.$message.success('操作成功！')
          this.loading = false
          this.re()
        })
        .catch(err => {
          this.loading = false
          console.log('错误：', err)
        })
    },
    headerInvoiceClassName({ row, column, rowIndex, columnIndex }) {
      if (column.label === '发票附件上传') {
        return ['headerInvoiceClassName']
      }
      return []
    },
    // 转审
    selectAuditor(row) {
      this.$api.addAuditor
        .taskSetAssignee({
          userId: row.id,
          processInstId: this.form.processInstId,
        })
        .then(res => {
          this.$message.success('操作成功！')
          this.re()
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 经费预支-详情
    expenditureDetailFn(row) {
      this.expenditureOptions = { ...row, id: row.advanceId }
      this.expenditureIsShow = true
    },
    async exportFileZipFn() {
      let execute = false
      execute = this.form.detailList.some(v => v.expenseFileList.some(n => !!n))
      if (execute) {
        this.loading = true
        this.$api.common
          .exportFileZip({ documentId: this.form.id })
          .then(res => {
            const blob = new Blob([res], { type: 'application/zip' })
            const fileName = `商务报销&${this.form.expenseNumber}&${this.form.id}`
            // const fileName = this.form.id
            if ('download' in document.createElement('a')) {
              // 非IE下载
              const elink = document.createElement('a')
              elink.download = fileName
              elink.style.display = 'none'
              elink.href = window.URL.createObjectURL(blob)
              document.body.appendChild(elink)
              elink.click()
              window.URL.revokeObjectURL(elink.href) // 释放URL 对象
              document.body.removeChild(elink)
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName)
            }
            this.loading = false
            this.$message.success('下载成功')
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
      } else {
        this.$message.warning('当前单据没有附件可下载')
      }
    },
    registrationIsShowFn() {
      if (this.form.registrationExpenditureId) {
        this.$api.register
          .selectDetailById(this.form.registrationExpenditureId)
          .then(res => {
            if (res.data) {
              this.oldRegistrationInfo = res.data
              this.registrationIsShow = true
            }
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.$message.warning('请选择登记单')
        this.registrationIsShow = false
      }
    },
    isShowType(n) {
      let arr = n.filePath.split('.')
      let str = arr[arr.length - 1]
      let srtArr = [
        'bmp',
        'dib',
        'pcp',
        'dif',
        'wmf',
        'gif',
        'jpg',
        'tif',
        'eps',
        'psd',
        'cdr',
        'iff',
        'tga',
        'pcd',
        'mpt',
        'png',
        'jpeg',
      ]
      if (srtArr.indexOf(str) == -1) {
        return true
      } else {
        return false
      }
    },
    imgIsShow(n, index) {
      this.img_index = this.imgList.findIndex(
        v =>
          (v.id && n.id && v.id === n.id) ||
          (v.fileName === n.fileName && v.filePath === n.filePath)
      )
      this.dialogVisible = true
    },

    init() {
      this.getCompanyList()
      this.getbusinessNameList()
      this.form = {}
      this.tableData = []
      if (this.options.id) {
        this.loading = true

        this.$api.businessExpense
          .selectDetailById(this.options.id)
          .then(res => {
            if (res.data) {
              this.form = res.data
              this.totalCounteract.sumAdvanceCost = this.form.sumAdvanceCost
                ? this.form.sumAdvanceCost
                : 0
              this.totalCounteract.sumCounteractCost = this.form.sumCounteractCost
                ? this.form.sumCounteractCost
                : 0
              // this.form.applyExpenseType = 'REGULAR_PAYMENT_REQUEST' //临时前端写死
              this.taskDefinitionKey = res.data.taskDefinitionKey
              this.instInvolved = {
                instInvolvedUserName: res.data.instInvolvedUserName
                  ? res.data.instInvolvedUserName
                  : [],
                status: res.data.status,
              }
            }
            if (res.data.detailList) {
              this.imgList = []
              res.data.detailList.forEach(item => {
                item.expenseFileList.forEach(v => {
                  if (!this.isShowType(v)) {
                    this.imgList.push(v)
                  }
                })
              })
            }
            this.loading = false
            this.payedFileList = res.data.payedFileList
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    //获取用户可以申请报销得商务名单
    getbusinessNameList() {
      this.$api.businessExpense
        .getBusinessNameList()
        .then(res => {
          this.businessNameList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    addApprove() {
      this.$refs.dg_formRef.validate(valid => {
        if (valid) {
          if (this.dg_form.status == 1) {
            if (this.dg_form.opinion == '') {
              this.dg_form.opinion = '通过'
            }
          }
          this.submit()
        }
      })
    },
    async submit() {
      this.loading = true
      let query = {
        id: this.form.id,
        opinion: this.dg_form.opinion,
      }
      // 点击通过
      if (this.dg_form.status == 1) {
        query.opinion = !query.opinion ? '通过' : query.opinion
        query.processInstId = this.form.processInstId
        this.editDialogLoading = true
        this.$api.addAuditor
          .disposeTask(query)
          .then(res => {
            this.editDialogLoading = false
            this.dialogFormVisible = false
            this.showEditDialog = false
            this.$message.success('操作成功！')
            this.loading = false
            this.re()
          })
          .catch(err => {
            this.editDialogLoading = false
            this.loading = false
            console.log(err)
          })
      } else if (this.dg_form.status == 2) {
        // todo 审批不通过
        this.editDialogLoading = true
        this.$api.businessExpense
          .approvalReject(query)
          .then(res => {
            this.editDialogLoading = false
            this.dialogFormVisible = false
            this.showEditDialog = false
            this.$message.success('操作成功！')
            this.loading = false
            this.re()
          })
          .catch(err => {
            this.editDialogLoading = false
            this.loading = false
            console.log(err)
          })
      }
    },

    handleClose() {
      this.dialogFormVisible = false
      this.$refs.dg_formRef.resetFields()
      this.dg_form.opinion = ''
    },

    getCompanyList() {
      this.$api.dict
        .listSysDictData('COMPANY_TYPE')
        .then(res => {
          this.companyTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('EXPENSE_MODE')
        .then(res => {
          this.dictData.expenseMode = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_DISBURSEMENT_TYPE')
        .then(res => {
          this.dictData.disbursementType = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 通过dictVal获取dictName
    getCompanyTypeName(val) {
      if (val) {
        const findResult = this.companyTypeList.find(item => item.dictVal == val)
        if (findResult) {
          return findResult.dictName
        } else {
          return ''
        }
      }
    },
    handleChangeLoading(e) {
      this.typeDialogLoading = e
    },
    showDialogForm(status) {
      this.auditStatus = status
      this.dg_form.opinion = ''
      this.dg_form.status = status
      this.dialogFormVisible = true
    },
  },
}
</script>

<style scoped lang="scss">
// @import "@/styles/edit.scss";

@import '@/styles/config.scss';

/deep/.form-deptName-width {
  width: 30% !important;
}
/deep/.businessOutSource {
  //费用类别背景色
  background-color: #b2f3fe;
  border: 1px solid #ddd !important;
}
.addButton {
  margin-bottom: 10px;
}

table {
  width: 100%;
}
td {
  width: 25vh;
}

.color_0 {
  color: #606266;
}
.el-form-item {
  margin-bottom: 0px !important;
}
.dictionary {
  padding-right: 0;
  padding-bottom: 0;
}
/deep/.bussinessLabelClassName {
  width: 16.6%;
}
/deep/.bussinessContentClassName {
  height: 75px !important;
}
// 附件编码
.invoice-file {
  display: flex;
  margin-top: 5px;
  .invoice-file-left {
    width: 85%;
  }
}
/deep/.headerInvoiceClassName {
  background-color: #b2f3fe !important;
}
</style>
